<template>
  <section class="grey lighten-5 pb-5 pt-5">
    <v-container grid-list-md light text-xs-center>
      <v-layout align-center row wrap>
        <v-row justify="center">
          <v-col class="text-center pt-5 pb-5" cols="12">
            <div class="flex display-1 font-weight-light">
              Restaurant Suite™ Everything You Need For Success
            </div>
            <div class="flex subheading font-weight-light">
              An all-inclusive restaurant marketing platform that includes
              multi-unit support, web & mobile apps, online ordering, push
              notifications, SMS messaging, email marketing, customer
              management, loyalty, gift cards, and more.
            </div>
          </v-col>
        </v-row>
      </v-layout>
    </v-container>
    <v-flex xs12>
      <v-container grid-list-xl>
        <v-layout align-center row wrap>
          <v-flex md4 xs12>
            <v-card>
              <v-img
                alt="Payment Solutions"
                contain
                src="@/assets/pos-dashboard-business.jpg"
              >
              </v-img>
              <v-card-title class="headline">Restaurant Dashboard</v-card-title>
              <v-card-text class="subheading grey--text text--darken-1">
                Displays marketing analytics, key point ind icators (KPIs), and
                performance metrics using data visualizations.
              </v-card-text>
            </v-card>
          </v-flex>

          <v-flex md4 xs12>
            <v-card>
              <v-img
                alt="digital strategies"
                contain
                src="@/assets/restaurant-dashboard.jpg"
              >
              </v-img>
              <v-card-title class="headline">Sales Reports & Data</v-card-title>
              <v-card-text class="subheading grey--text text--darken-1">
                Engage your customers with email notifications, text messaging,
                rewards & loyalty, push notifications and more.
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex md4 xs12>
            <v-card>
              <!--suppress HtmlUnknownTarget, HtmlUnknownTarget -->
              <v-img
                alt="digital strategies"
                contain
                src="@/assets/reports-pos-best.jpg"
              >
              </v-img>
              <v-card-title class="headline"
                >Customer Activity & Analytics
              </v-card-title>
              <v-card-text class="subheading grey--text text--darken-1">
                Gain valuable insights into consumer data including buying
                habits, frequency, purchasing volume and other KPIs.
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex md4 xs12>
            <v-card>
              <!--suppress HtmlUnknownTarget -->
              <v-img
                alt="Digital Marketing"
                contain
                src="@/assets/email-marketing-restaurant-local.jpg"
              >
              </v-img>
              <v-card-title class="headline"
                >Email Marketing & Campaigns
              </v-card-title>
              <v-card-text class="subheading grey--text text--darken-1">
                Create email campaigns to encourage repeat visits, broadcast
                special events, promote daily specials and more.
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex md4 xs12>
            <v-card>
              <!--suppress HtmlUnknownTarget, HtmlUnknownTarget -->
              <v-img
                alt="digital strategies"
                contain
                src="@/assets/restaurant-mobile-apps-ri.jpg"
              >
              </v-img>
              <v-card-title class="headline"
                >Real-Time Sync & Updates
              </v-card-title>
              <v-card-text class="subheading grey--text text--darken-1">
                Ensure your customers have your latest restaurant menu. Update
                your restaurant menu once, and sync across all devices.
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex md4 xs12>
            <v-card>
              <!--suppress HtmlUnknownTarget -->
              <v-img
                alt="Mobile Apps"
                contain
                src="@/assets/restaurant-send-promotional-offers.jpg"
              >
              </v-img>
              <v-card-title class="headline"
                >Push Notifications & SMS
              </v-card-title>
              <v-card-text class="subheading grey--text text--darken-1">
                Send notifications of upcoming events, specials, promotions, and
                invitations via email, push notifications and SMS messaging.
              </v-card-text>
              <!--<v-card-actions>
                            <router-link to="/localwebsitedesign/"><v-btn aria-label="local websites"  color="teal">Local Websites</v-btn></router-link>
                          </v-card-actions>-->
            </v-card>
          </v-flex>
          <v-flex md4 xs12>
            <v-card>
              <v-img
                alt="digital strategies"
                contain
                src="@/assets/loyalty-pos-apps.jpg"
              >
              </v-img>
              <v-card-title class="headline"
                >Customer Loyalty & Incentives
              </v-card-title>
              <v-card-text class="subheading grey--text text--darken-1">
                Reward loyal customers with loyalty points. Track everything
                including purchases, loyalty points, redemptions, and more.
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex md4 xs12>
            <v-card>
              <!--suppress HtmlUnknownTarget, HtmlUnknownTarget, HtmlUnknownTarget -->
              <v-img
                alt="digital strategies"
                contain
                src="@/assets/restaurant-loyalty-program.jpg"
              >
              </v-img>
              <v-card-title class="headline">Digital Gift Cards</v-card-title>
              <v-card-text class="subheading grey--text text--darken-1">
                Provide your customers with digital gift cards that entice
                customers to order online without the need for a 3rd party.
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex md4 xs12>
            <v-card>
              <!--suppress HtmlUnknownTarget, HtmlUnknownTarget -->
              <v-img
                alt="digital strategies"
                contain
                src="@/assets/mobile-pos-apps.jpg"
              >
              </v-img>
              <v-card-title class="headline"
                >Notifcations & Messaging
              </v-card-title>
              <v-card-text class="subheading grey--text text--darken-1">
                Send customer notifications instantly to encourage repeat visits
                and keep your customers tuned in.
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex md4 xs12>
            <v-card>
              <v-img
                alt="digital strategies"
                contain
                src="@/assets/online-ordering-restaurant-web-design.jpg"
              >
              </v-img>
              <v-card-title class="headline"
                >Menu & Daily Specials
              </v-card-title>
              <v-card-text class="subheading grey--text text--darken-1">
                Create products with types, attributes, and modifiers. Define
                different product types and prices for each location.
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex md4 xs12>
            <v-card>
              <v-img
                alt="digital strategies"
                contain
                src="@/assets/online-ordering-terminal-restaurant.jpg"
              >
              </v-img>
              <v-card-title class="headline"
                >Sales & Order Management
              </v-card-title>
              <v-card-text class="subheading grey--text text--darken-1">
                Manage takeout and delivery orders through a centralized digital
                ordering system allowing for more efficient management.
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex md4 xs12>
            <v-card>
              <v-img
                alt="digital strategies"
                contain
                src="@/assets/restaurant-mobile-apps-ri.jpg"
              >
              </v-img>
              <v-card-title class="headline"
                >In-Store, Delivery & Take-Out
              </v-card-title>
              <v-card-text class="subheading grey--text text--darken-1">
                Customers can browse your menu items and schedule for takeout
                and/or delivery from the location of choice.
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex md4 xs12>
            <v-card>
              <!--suppress HtmlUnknownTarget -->
              <v-img
                alt="digital strategies"
                contain
                src="@/assets/order-history-restaurants.jpg"
              >
              </v-img>
              <v-card-title class="headline"
                >Order Ahead & Order History
              </v-card-title>
              <v-card-text class="subheading grey--text text--darken-1">
                Customers can browse your menu items and schedule for takeout
                and/or delivery from the location of choice.
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex md4 xs12>
            <v-card>
              <!--suppress HtmlUnknownTarget -->
              <v-img
                alt="digital strategies"
                contain
                src="@/assets/payment-processing-restaurant.jpg"
              >
              </v-img>
              <v-card-title class="headline"
                >Payments & Processing
              </v-card-title>
              <v-card-text class="subheading grey--text text--darken-1">
                Customer payments are deposited directly into your bank account
                in real-time. with no commission fees.
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex md4 xs12>
            <v-card>
              <!--suppress HtmlUnknownTarget, HtmlUnknownTarget -->
              <v-img
                alt="digital strategies"
                contain
                src="@/assets/delivery-restaurant.jpg"
              >
              </v-img>
              <v-card-title class="headline"
                >Delivery Zones & Tracking
              </v-card-title>
              <v-card-text class="subheading grey--text text--darken-1">
                Setup delivery zones to ensure customers are in your delivery
                zone. Track the complete delivery process.
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex md4 xs12>
            <v-card>
              <v-img
                alt="digital strategies"
                contain
                src="@/assets/restaurant-menu-update-web.jpg"
              >
              </v-img>
              <v-card-title class="headline"
                >Product List & Updates
              </v-card-title>
              <v-card-text class="subheading grey--text text--darken-1">
                Update your menu's product list across all digital channels
                including IOS, Android and Web apps.
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex md4 xs12>
            <v-card>
              <!--suppress HtmlUnknownTarget -->
              <v-img
                alt="digital strategies"
                contain
                src="@/assets/restaurant-analytics.jpg"
              >
              </v-img>
              <v-card-title class="headline"
                >Analytics & Reporting
              </v-card-title>
              <v-card-text class="subheading grey--text text--darken-1">
                Access over 100+ reports and gain insights into every level of
                your customer analytics across all digital channels and
                locations.
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex md4 xs12>
            <!--suppress HtmlUnknownTarget -->
            <v-card>
              <v-img
                alt="digital strategies"
                contain
                src="@/assets/menu-upates-restaurant.jpg"
              >
              </v-img>
              <v-card-title class="headline">Multi-Unit Support</v-card-title>
              <v-card-text class="subheading grey--text text--darken-1">
                Easily manage all your locations thru a single dashboard.
                Customers select the desired location and place online takeout &
                delivery orders.
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
        <div class="grey lighten-5 text-xs-center pt-5 pb-5">
          <v-flex xs12>
            <v-container grid-list-xl>
              <v-layout align-center column justify-center>
                <div class="text-xs-center pb-5 pt-5">
                  <router-link to="/how-it-works">
                    <v-btn
                      aria-label="Restaurant Performance Pricing"
                      color="primary"
                      dark
                      rounded
                      >LEARN HOW IT WORKS
                    </v-btn>
                  </router-link>
                </div>
              </v-layout>
            </v-container>
          </v-flex>
        </div>
      </v-container>
    </v-flex>
  </section>
</template>

<script>
export default {
  name: "RestaurantTech2"
};
</script>

<style scoped></style>
