<template>
  <section class="pb-5 pt-5">
    <v-flex xs12>
      <v-container grid-list-xl>
        <v-layout align-center column wrap>
          <v-card class="elevation-2 pb-5">
            <v-card-title class="headline"
              >What is Restaurant Image&trade;?
            </v-card-title>
            <v-card-text
              >Restaurant Image&trade; is a SAAS restaurant advertising platform
              that enables us to target local consumers within a specified
              demographics of a Restaurant area.
            </v-card-text>

            <v-card-text
              >We aggressively market your restaurant in your local area. We
              promote your restaurant menu online and push online ordering to
              your target audiences. The goal is to get local prospects to
              download your custom branded web & mobile apps. We market your
              apps in your local area on paid search and social media sites. We
              then retarget these consumers to keep them coming back.
            </v-card-text>
            <v-card-text
              >Our strategy targets local customers in a specified demographic
              range using social proximity, locality, and re-targeting data. Our
              technology helps you connect with the “always-connected” customer,
              and enables you to keep your brand in front of your local
              audience. We deliver an unmatched customer experience to your
              customers.
            </v-card-text>
          </v-card>

          <v-card class="elevation-2">
            <v-card-title class="headline">How much does it cost?</v-card-title>
            <v-card-text>
              Simply set your monthly ad spend and we take care of the rest. We
              actively manage and market your brand at no cost to you. You pay
              the Ad networks (ex. Google & Facebook) when a local customer
              responds to our creative ads. The more you pay, the higher
              visibility and the greater number of potential customers. We
              provide you with a custom tailored strategy for each location that
              includes how many Restaurant searches were conducted in the
              previous month from similar restaurants and make recommendations.
              No minimum ad spend required.
            </v-card-text>
            <v-card-text>
              Our main objective is to aggressively market your restaurant
              locally while focusing on your "Return On Ad Spend". We are a
              performance-based marketing company and we get paid only when a
              customer orders takeout or delivery online.
            </v-card-text>
            <v-card-text>
              We are big believers in data analytics. We monitor demographics,
              behavior, locality, and other key point indicators (KPI). Our
              marketing strategy combines this data and other basic information
              such as how many Restaurant searches were conducted in the
              previous month from similar restaurants, the volume of online
              orders needed to break even, and the recommended ad spend.
            </v-card-text>
            <v-card-text
              >Additional Fees: * Google, Facebook, and Bing ad spend varies. *
              iOS apps require a $99 yearly license fee paid to Apple. * Push
              notifications, SMS and Email marketing require small additional
              fees based on volume.
            </v-card-text>
            <v-card-text
              >For exact SMS, push notifications, and email pricing click here:
              https://www.twilio.com/pricing
            </v-card-text>
          </v-card>

          <v-card class="elevation-2 pt-5 pb-5">
            <v-card-title class="headline"
              >How does RestaurantImage&trade; make money when everything is
              FREE?
            </v-card-title>
            <v-card-text>
              Restaurant Image &trade; is a performance-based marketing platform
              specifically designed for restaurants. We get paid by your
              customers by adding a small transaction fee ($0.99) to each online
              order. Your customers pay this fee when placing orders online.
              This provides us an incentive to do our best work for you and
              ensures that we always put our best foot forward. (otherwise, we
              will never get paid).
            </v-card-text>
          </v-card>
          <v-card class="elevation-2 pt-5 pb-5">
            <v-card-title class="headline"
              >Where will my Restaurant Ads&trade; appear if I advertise with
              Restaurant Connect&trade;
            </v-card-title>
            <v-card-text>
              Your restaurant ad appears on local search engines such as Google,
              Bing, and Yahoo and also appears on social media sites such as
              Facebook, Instagram, and Pinterest. Other channels include
              Restaurant directories, niche websites, news media sites like
              Yelp, Fox News, CNN, and other popular websites.
            </v-card-text>
          </v-card>

          <v-card class="elevation-2 pt-5 pb-5">
            <v-card-title class="headline"
              >Can I use Restaurant Image&trade; if I don't have a Website?
            </v-card-title>
            <v-card-text>
              Yes, all packages include a custom branded web, iOS, and Android
              mobile app. Some of our clients prefer to use our technology and
              replace their existing website with ours. Easily update your
              digital location all thru a single easy to use control panel. All
              orders sync to an in-store terminal for real-time order
              notifications.
            </v-card-text>
          </v-card>
          <v-card class="elevation-2 pt-5 pb-5">
            <v-card-title class="headline"
              >How will Restaurant Connect&trade; help my restaurant
            </v-card-title>
            <v-card-text>
              We connect restaurants with local customers. We target consumers
              who are actively searching for restaurants like yours on search
              engines and social media sites. We help you cost-effectively
              target local consumers in your neighborhood within a ten-mile
              radius of your restaurant. This drives foot traffic to your
              restaurant and encourages online orders. We make our money on
              online orders. The post-covidd-19 pandemic has formed a new
              “consumer-driven” restaurant model. It's vital to have access to
              the latest technology, marketing tools, marketing channels, and
              customer engagement tools.
            </v-card-text>
          </v-card>
          <v-card class="elevation-2 pt-5 pb-5">
            <v-card-title class="headline"
              >What are Restaurant Listings&trade;?
            </v-card-title>
            <v-card-text>
              Restaurant Listing&trade; is any online mention of the name,
              address, and phone number for a Restaurant business. Listings are
              displayed on local business directories, news media sites, and
              social media platforms. This helps in organic SEO for your
              restaurant.
            </v-card-text>
          </v-card>
          <v-card class="elevation-2 pt-5 pb-5">
            <v-card-title class="headline"
              >What are Restaurant Ads&trade;?
            </v-card-title>
            <v-card-text>
              Restaurant Ads&trade; are paid search ads for Google, Bing, and
              Facebook that help drive consumers into your physical store
              location. These professionally created ads generate foot traffic,
              calls, and online orders. We only get paid on online orders.
            </v-card-text>
          </v-card>
          <v-card class="elevation-2 pt-5 pb-5">
            <v-card-title class="headline"
              >Should I use Restaurant Connect&trade; if I already use other
              marketing services?
            </v-card-title>
            <v-card-text>
              Yes, Restaurant Connect&trade; targets local consumers arounded
              your actual physical store address. You will increase foot
              traffic, increase takeout and delivery orders, and revenue. Many
              of our clients see an increase of sales by 15% week over week.
            </v-card-text>
          </v-card>
        </v-layout>
      </v-container>
    </v-flex>
  </section>
</template>

<script>
export default {
  name: "FaqTop"
};
</script>

<style scoped></style>
